import { render, staticRenderFns } from "./IntegratedFreezerFilters.vue?vue&type=template&id=4b56fbfa&scoped=true&"
import script from "./IntegratedFreezerFilters.vue?vue&type=script&lang=js&"
export * from "./IntegratedFreezerFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b56fbfa",
  null
  
)

export default component.exports